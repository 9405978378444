import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllCheckIn,printDcCheckin} from "../../services/CheckInServices";
import { displayError } from "../../helpers";
function CheckInTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const columns = useMemo(
        () => [
            {
                fieldName: "plant",
                headerName: 'Plant'
            },
            {
                fieldName: "checkout_no",
                headerName: 'CheckOut No.'
            },
            {
                fieldName: "delivery_challan_no",
                headerName: 'DC Nos'
            },
            {
                fieldName: "delivery_challan_date",
                headerName: 'DC Date'
            },
            {
                fieldName: "order_no",
                headerName: 'SO Nos'
            },
            {
                fieldName: 'customer_name', 
                headerName: 'Billing Name'
            },
           
            {
                fieldName: "consignee_name",
                headerName: 'Site Name'
            },
            {
                fieldName: "grade",
                headerName: 'Grade'
            },
            {
                fieldName: "dc_qty",
                headerName: 'DC Qty'
            },
            {
                fieldName: "accepted_qty",
                headerName: 'Accepted Qty'
            },
            {
                fieldName: "customer_status",
                headerName: 'Customer Status'
            },
            {
                fieldName: "cus_dc_status",
                headerName: 'DC Status'
            },
            {
                fieldName: "vehicle_no",
                headerName: 'Vehicle No'
            },
            {
                fieldName: 'driver_name', 
                headerName: 'Drivers Name'
            },
           
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditCheckIn = (checkinId, event) => {
       
       navigate(`/CheckInEdit/${checkinId}/`);
    };

    const onPrintDeliveryChallan = (checkinId, event) => {
        event.preventDefault();
        setIsLoading(true);
        printDcCheckin(cookies, checkinId)
        .then(response => {
            const file = new Blob([response], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
            setIsLoading(false);
        })
        .catch(error => {
            // setRefreshKey(oldKey => oldKey +1);
            console.log(error);
            displayError(error,'Print Failed');
            setIsLoading(false);
        });  
    };
  
    

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(checkin => {
             const csvRow = {
                    plant:checkin.plant,
                    checkout_no: checkin.checkout_no,
                    delivery_challan_no:checkin.delivery_challan_no,
                    delivery_challan_date:checkin.delivery_challan_date,
                    order_no:checkin.order_no,
                    customer_name:checkin.customer_name,
                    consignee_name:checkin.consignee_name,
                    grade:checkin.grade,
                    dc_qty:checkin.dc_qty,
                    accepted_qty:checkin.accepted_qty,
                    customer_status:checkin.customer_status,
                    cus_dc_status:checkin.cus_dc_status,
                    vehicle_no:checkin.vehicle_no,
                    driver_name:checkin.driver_name,
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of CheckIn',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(checkin => ({
                plant:checkin.plant,
                checkout_no: checkin.checkout_no,
                delivery_challan_no:checkin.delivery_challan_no,
                delivery_challan_date:checkin.delivery_challan_date,
                order_no:checkin.order_no,
                customer_name:checkin.customer_name,
                consignee_name:checkin.consignee_name,
                grade:checkin.grade,
                dc_qty:checkin.dc_qty,
                accepted_qty:checkin.accepted_qty,
                customer_status:checkin.customer_status,
                cus_dc_status:checkin.cus_dc_status,
                vehicle_no:checkin.vehicle_no,
                driver_name:checkin.driver_name,
            })),
            'CheckInList.pdf'
        );
    };

    useEffect(() => {
        getAllCheckIn(cookies)
        .then( checkinList => {
            const tableData = checkinList.checkin_list
            .map(checkin => ({
                id :checkin.id,
                checkout_no: checkin.checkout_no,
                delivery_challan_no:checkin.delivery_challan.delivery_challan_no,
                delivery_challan_date:checkin.delivery_challan.delivery_challan_date,
                order_no:checkin.delivery_challan.work_schedule.sales_order_detail.sales_order.order_no,
                customer_name:checkin.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.customer.name,
                consignee_name:checkin.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.name,
                grade:checkin.delivery_challan.work_schedule.sales_order_detail.product.name,
                dc_qty:checkin.delivery_challan.dc_qty,
                accepted_qty:checkin.accepted_qty,
                customer_status:checkin.customer_status? checkin.customer_status:"Waiting for checkin",
                cus_dc_status:checkin.cus_dc_status? checkin.cus_dc_status:"Waiting for checkin",
                vehicle_no:checkin.delivery_challan.vehicle.name,
                driver_name:checkin.driver_name,
                hide_row_edit:checkin.customer_status?"No":"Yes",
                plant:checkin.delivery_challan.work_schedule.plant.alias_name
            }));
            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
<>
        {/* <div id="outer-container"  className="App" >  */}
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of CheckIn</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of CheckIn'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditCheckIn}
                            hideDelete={true}
                            // onRowDelete={onDeleteCheckIn}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            onRowPrint={onPrintDeliveryChallan}
                            hidePrint ={false}
                        />
                </div>
                </div> 

            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/CheckIn")}}>Back</button>
        {/* </div> */}
        </>  
    );

}



export default CheckInTable;