import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllInvoices,printSalesInvoice } from "../../services/SalesInvoiceServices";
import { Row,Col,Button} from "reactstrap";
import { Link } from "react-router-dom";
import { DatePicker } from 'antd';
import moment  from 'moment';
import { getAllPlants} from '../../services/PlantServices';
import { getAllCustomerMain} from '../../services/CustomerMainServices';
import { displayError } from "../../helpers";
const {RangePicker} = DatePicker;
function SalesInvoiceTable() {
    const InitialData = {
        records: []
    };
    const [data, setData] = useState(InitialData);
    const [inputs, setInputs] = useState({});
    const navigate = useNavigate(); 
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);
    const [plants, setPlants] = useState([]);
    const [customers, setCustomers] = useState([]); 
    // Initial state for from_date and to_date
    const initialFromDate = moment().startOf('month');
    const initialToDate =  moment();
    const [fromDate, setFromDate] = useState(initialFromDate);
    const [toDate, setToDate] = useState(initialToDate);  
    const columns = useMemo(
        () => [
            {
                fieldName: 'plant_alias', 
                headerName: 'Plant'
            },
            {
                fieldName: "doc_type",
                headerName: 'Doc Type'
            },
            {
                fieldName: "invoice_no",
                headerName: 'Invoice Number'
            },
            {
                fieldName: "invoice_date",
                headerName: 'Invoice Date'
            },
            {
                fieldName: "customer_name",
                headerName: 'Customer Name'
            },
            {
                fieldName: "project_name",
                headerName: 'Site Name'
            },
            {
                fieldName: "invoice_amt",
                headerName: 'Invoice Amount'
            },
            {
                fieldName: 'salesrep_name', 
                headerName: 'Marketting'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);
    
    const handleDateChange = (values) => {
        if ((values) && (values.length === 2)) {
            setFromDate(values[0]);
            setToDate(values[1]);
        }
    };

    const onEditSalesPosting = (salespostId, event) => {
       navigate(`/SalesInvoiceEdit/${salespostId}/`);
    };

    const onPrintSalesPosting = (salespostId, event) => {
        event.preventDefault();
        setIsLoading(true);
        printSalesInvoice(cookies, salespostId)
        .then(response => {
            const file = new Blob([response], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
            setIsLoading(false);
        })
        .catch(error => {
            // setRefreshKey(oldKey => oldKey +1);
            console.log(error);
            displayError(error,'Print Failed');
            setIsLoading(false);
        });  
    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(invoice => {
                const csvRow ={
                    plant_alias: invoice.plant_alias,
                    doc_type:invoice.doc_type,
                    invoice_no:invoice.invoice_no,
                    invoice_date: invoice.invoice_date,
                    customer_name:invoice.customer_name,
                    project_name:invoice.project_name,
                    invoice_amt:  invoice.invoice_amt,
                    salesrep_name:invoice.salesrep_name,
                }
                return csvRow;
                }));
    };

    const handleChange = (event) => {
        const name = event.target.name;
        try
        {
            setInputs(values => ({ ...values, [name]: event.target.value }))
        }
        catch(e)
        {
            console.log(e+"error");
        } 
    }
    
    const Home = () => {
        navigate('/Home')
    }

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Sales Invoice',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(invoice => ({
                    plant_alias: invoice.plant_alias,
                    id: invoice.id,
                    invoice_amt:  invoice.invoice_amt,
                    invoice_no:invoice.invoice_no,
                    invoice_date: invoice.invoice_date,
                    invoice_time: invoice.invoice_time,
                    doc_type:invoice.doc_type,
                    order_no:invoice.order_no,
                    customer_name:invoice.customer_name,
                    salesrep_name:invoice.salesrep_name,
                    consignee_name:invoice.consignee_name,
                    project_name:invoice.project_name,
                    pan_no:invoice.pan_no,
                    gst_no: invoice.gst_no
            })),
            'SalesInvoice.pdf'
        );
    };

    useEffect(() => {
        getAllCustomerMain(cookies)
        .then (
            customerList => {
                const customers = customerList.customer_list.map(
                    customer => {
                        return { value: customer.id, label: customer.name }
                    }
                );
                setCustomers(customers);
            }
        )
    }, []);

    useEffect(() => {
        //Load data for Plant dropdown
        getAllPlants(cookies)
        .then (
            plantList => {
                const plants = plantList.plant_list.map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setPlants(plants);
            }
        )
    }, []);

    const handleSubmit=(event)=> {
        event.preventDefault();
        console.log(JSON.stringify(inputs)+"inputs");
        if ((inputs.plant_id) && (inputs.customer_id) && (fromDate) && (toDate))
        {
            getAllInvoices(cookies,{
                from_date:fromDate.format('DD-MM-YYYY'),
                upto_date:toDate.format('DD-MM-YYYY'),
                plant_id:inputs.plant_id,
                customer_id:inputs.customer_id})
            .then(response => {
                const tableData = response.invoice_list.map(invoice => (
                {
                    plant_alias: invoice.plant.alias_name,
                    id: invoice.id,
                    invoice_amt:  invoice.invoice_amt,
                    invoice_no:invoice.prefix + invoice.invoice_no,
                    invoice_date: invoice.invoice_date,
                    invoice_time: invoice.invoice_time,
                    doc_type:'SALES',
                    order_no:invoice.sales_order.prefix+invoice.sales_order.order_no,
                    customer_name:invoice.sales_order.consignee.customer.name,
                    salesrep_name:invoice.sales_order.consignee.customer.salesrep.name,
                    consignee_name:invoice.sales_order.consignee.name,
                    project_name:invoice.sales_order.consignee.project_name,
                    pan_no:invoice.sales_order.consignee.customer.pan_no,
                    gst_no: invoice.sales_order.consignee.customer.gst_no
                }));
                setData({
                    records: tableData
                });
                setIsLoading(false);
            }).catch((error) => {
                console.log(error+"error");
                // displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("plant,customer,from_date,upto_date must not be empty.", "", "info");  
        }
    }
    
    return (
        <>
        {/* <div id="outer-container"  className="App" >  */}
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
                <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Sales Invoice</h3>
                <br/>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="container">
                        <Row>
                            <Col xl={1} lg={12} md={12}>
                            </Col>
                            <Col xl={10} lg={11} md={11}>
                                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-2 form-control-panel ">
                                    <label htmlFor="plant_id" className="form-group col-sm-0 text-right"></label>
                                    <select  id="plant_id" className="browser-default custom-select col-sm-3 mandatory-form-control" onChange={handleChange}  required name="plant_id" value={inputs.plant_id || ""} >
                                        <option value=''>Please Select Plant Location</option>
                                            {plants.map((item) => (
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                            ))}
                                    </select>
                                    <label htmlFor="customer_id" className="form-group col-sm-0 text-right"></label>
                                    <select  id="customer_id" className="browser-default custom-select col-sm-3 mandatory-form-control" onChange={handleChange}  required name="customer_id" value={inputs.customer_id || ""} >
                                        <option value=''>Please Select Customer Name </option>
                                        {customers.map((item) => (
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="grnHCompcode" className="form-group col-sm-0 text-left"></label>
                                    <RangePicker 
                                    defaultValue={[initialFromDate, initialToDate]}
                                    format="DD-MM-YYYY"
                                    onChange={handleDateChange}
                                    style={{height:"40px",width:"270px"}} 
                                    />
                                    <div>    
                                        <Button  type="submit"
                                            style={{backgroundColor: "RGB(188,232,253)",
                                            fontWeight:"bold",
                                            }}
                                            >
                                            <i className="fa fa-search fa-2x" id="myData" aria-hidden="true" style={{color:"rgb(93, 156, 233)"}}></i>
                                        </Button> &nbsp;
                                        <Link  to={'/Home'} >
                                            <i className="fa fa-home fa-2x"aria-hidden="true"></i>
                                        </Link>
                                    </div> 
                                </div>
                            </Col>
                        </Row> 
                    </div>
                </form>
           
                <div className="container item-list-table-container">
                    <div className="table-responsive">
                        <ReactTableWrapper
                            title='List of Sales 'Invoice
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditSalesPosting}
                            hideDelete={true}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            onRowPrint={onPrintSalesPosting}
                            hidePrint ={false}
                        />
                    </div>
                </div> 
            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/SalesInvoice")}}>Back</button>
        {/* </div> */}
        </>
    );
}

export default SalesInvoiceTable;